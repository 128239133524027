/*###########################################################################
// Slider three col images
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';

const sliderThreeColImages = {
  config: {
    sliderThreeColImages: '.sliderThreeColImages'
  },

  elements: {
    sliderThreeColImages: null
  },

  init() {
    const self = this

    self.elements.sliderThreeColImages = document.querySelectorAll(self.config.sliderThreeColImages)

    if (self.elements.sliderThreeColImages !== null && window.innerWidth < 768) {
      for (let i = 0; i < self.elements.sliderThreeColImages.length; i++) {
        const slider = self.elements.sliderThreeColImages[i]

        self.handleSliderThreeColImages(slider)
      }
    }
  },

  handleSliderThreeColImages(slider) {
    const self = this

    const sliderInstance = new Swiper(slider, {
      modules: [Autoplay],
      spaceBetween: 16,
      centeredSlides: true,
      speed: 6000,
      autoplay: {
        delay: 1
      },
      loop: true,
      slidesPerView: 1.25,
      allowTouchMove: false,
      disableOnInteraction: false
    })
  }
}

window.addEventListener('load', function () {
  sliderThreeColImages.init()
})
