/*###########################################################################
// Header
//#########################################################################*/

import { throttle } from '../utilities/throttleDebounce'
import { inViewport } from '../utilities/inViewport'
import { gsap } from "gsap";
import "../vendor/SplitText";
gsap.registerPlugin(SplitText);

const header = {
  config: {
    header: '.header',
    headerNav: '.header__nav',
    headerNavLink: '.header__nav__link',
    headerNavBtn: '.header__nav-btn',
    headerNavBtnTrigger: '.header__nav-btn__trigger',
    headerLogo: '.header__logo',
    headerScrollProgress: '.header__scroll-progress',
		headerSidemenuHoverHelperTop: '.header__sidemenu-hover-helper-top',
		headerSidemenuHoverHelperLeft: '.header__sidemenu-hover-helper-left',

		// Approach
		headerApproach: '.header__sidemenu--approach',
    headerApproachLink: '.header__nav__link--approach',
    headerApproachOverlay: '.header__sidemenu--approach .header__sidemenu__overlay',
    headerApproachCloseBtn: '.header__sidemenu--approach .header__sidemenu-close-btn',

		// Projects
		headerProjects: '.header__sidemenu--projects',
    headerProjectsLink: '.header__nav__link--projects',
    headerProjectsOverlay: '.header__sidemenu--projects .header__sidemenu__overlay',
    headerProjectsCloseBtn: '.header__sidemenu--projects .header__sidemenu-close-btn',
  },

  elements: {},

  state: {
    isMobile: false,
    isOpen: false
  },

  init() {
    const self = this

    self.elements.header = document.querySelector(self.config.header)

    if (self.elements.header !== null) {
      self.elements.headerNav = self.elements.header.querySelector(self.config.headerNav)
      self.elements.headerNavLinks = self.elements.header.querySelectorAll(self.config.headerNavLink)
      self.elements.headerNavBtn = self.elements.header.querySelector(self.config.headerNavBtn)
      self.elements.headerNavBtnTrigger = self.elements.header.querySelector(self.config.headerNavBtnTrigger)
      self.elements.headerLogo = self.elements.header.querySelector(self.config.headerLogo)
      self.elements.headerScrollProgress = self.elements.header.querySelector(self.config.headerScrollProgress)
			self.elements.headerSidemenuHoverHelperTop = self.elements.header.querySelector(self.config.headerSidemenuHoverHelperTop)
			self.elements.headerSidemenuHoverHelperLeft = self.elements.header.querySelector(self.config.headerSidemenuHoverHelperLeft)

			// Approach
      self.elements.headerApproach = document.querySelector(self.config.headerApproach)
      self.elements.headerApproachLink = self.elements.header.querySelector(self.config.headerApproachLink)
      self.elements.headerApproachOverlay = document.querySelector(self.config.headerApproachOverlay)
      self.elements.headerApproachCloseBtn = document.querySelector(self.config.headerApproachCloseBtn)

			// Projects
      self.elements.headerProjects = document.querySelector(self.config.headerProjects)
      self.elements.headerProjectsLink = self.elements.header.querySelector(self.config.headerProjectsLink)
      self.elements.headerProjectsOverlay = document.querySelector(self.config.headerProjectsOverlay)
      self.elements.headerProjectsCloseBtn = document.querySelector(self.config.headerProjectsCloseBtn)

      // Handle scroll
      //
      self.handleScroll()

      // Handle nav links
      //
      if (self.elements.headerNavLinks !== null) {
        for (let i = 0; i < self.elements.headerNavLinks.length; i++) {
          const headerNavLink = self.elements.headerNavLinks[i]
          self.handleNavLink(headerNavLink)
        }
      }

      // Handle nav btn
      //
      self.handleNavBtn()

			// Handle nav hover helpers (closing of hover sidemenus)
			//
			if (self.elements.headerSidemenuHoverHelperTop !== null) {
				self.elements.headerSidemenuHoverHelperTop.addEventListener('mousemove', function() {
					// Handle closing of sidemenus
					//
					if (self.elements.headerApproach !== null && self.elements.headerApproach.classList.contains('header__sidemenu--open')) {
						self.closeApproachMenu()
					}

					if (self.elements.headerProjects !== null && self.elements.headerProjects.classList.contains('header__sidemenu--open')) {
						self.closeProjectsMenu()
					}
				})
			}

			if (self.elements.headerSidemenuHoverHelperLeft !== null) {
				self.elements.headerSidemenuHoverHelperLeft.addEventListener('mousemove', function() {
					// Handle closing of sidemenus
					//
					if (self.elements.headerApproach !== null && self.elements.headerApproach.classList.contains('header__sidemenu--open')) {
						self.closeApproachMenu()
					}

					if (self.elements.headerProjects !== null && self.elements.headerProjects.classList.contains('header__sidemenu--open')) {
						self.closeProjectsMenu()
					}
				})
			}

			// Handle approach
      //
      self.handleApproach()

      // Handle approach close
      //
      self.handleApproachClose()

      // Handle projects
      //
      self.handleProjects()

      // Handle projects close
      //
      self.handleProjectsClose()

      // Set nav height
      //
      self.setNavHeight()

      // Handle Scroll Progress
      //
      if (self.elements.headerScrollProgress !== null) {
        self.handleScrollProgress()
      }

      // Set state
      //
      if (window.innerWidth >= 1024) {
        self.state.isDesktop = true
      } else {
        self.state.isDesktop = false
      }

      window.addEventListener('resize', throttle(function () {
        // Set nav height
        //
        self.setNavHeight()

        // Set state
        //
        if (window.innerWidth >= 1024) {
          self.state.isDesktop = true
        } else {
          self.state.isDesktop = false
        }

				// Ensure header nav is interactive on smaller screens
				//
				if (window.innerWidth < 1024) {
					self.elements.headerNav.style.pointerEvents = ''
				}
      }, 100))
    }
  },

  handleScroll() {
    const self = this

    const sectionToHideHeader = document.querySelector('.approachScenes, .projects-overview__cards, .projects-home__cards, .projects-craft__cards, .projects-brand__cards, .team-fit')

    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    // Initial check
    //
    if (scrollPosition > 20) {
      self.elements.header.classList.add('header--scrolled')
      self.elements.header.classList.remove('header--at-top')
    } else {
      self.elements.header.classList.remove('header--scrolled')
      self.elements.header.classList.add('header--at-top')
    }

    // Scroll session (minimise header only after 100/200px)
    //
    let currentInitialScrollPoint = 0
    let isScrollingDown = false

    if (window.lenis !== undefined) {
      window.lenis.on("scroll", throttle(function () {
        scrollPosition = window.lenis.actualScroll

        // console.log({
        //   actualScroll: window.lenis.actualScroll,
        //   scroll: window.lenis.scroll,
        //   direction: window.lenis.direction
        // });

        if (self.state.isOpen === false) {
          if (scrollPosition > 20) {
            self.elements.header.classList.add('header--scrolled')
            self.elements.header.classList.remove('header--at-top')
          } else {
            self.elements.header.classList.remove('header--scrolled')
            self.elements.header.classList.add('header--at-top')
          }

          if (scrollPosition > 2) {
            if (sectionToHideHeader !== null && inViewport(sectionToHideHeader, 0)) {
              self.elements.header.classList.remove('header--scrolling-up')
              self.elements.header.classList.add('header--minimised')
            } else if (window.lenis.direction === -1) {
              isScrollingDown = false
              currentInitialScrollPoint = scrollPosition
              self.elements.header.classList.add('header--scrolling-up')
              self.elements.header.classList.remove('header--minimised')
            } else if (window.lenis.direction === 1) {
              if (isScrollingDown === false) {
                currentInitialScrollPoint = scrollPosition
                isScrollingDown = true
              } else {
                if (isScrollingDown === true && scrollPosition > (currentInitialScrollPoint + (window.innerWidth < 768 ? 100 : 150))) {
                  self.elements.header.classList.add('header--minimised')
                  self.elements.header.classList.remove('header--scrolling-up')
                }
              }
            } else {
              self.elements.header.classList.add('header--minimised')
              self.elements.header.classList.remove('header--scrolling-up')
            }
          } else {
            self.elements.header.classList.remove('header--scrolling-up')
            self.elements.header.classList.remove('header--minimised')
          }
        }
      }, 50))
    } else {
      let lastScrollTop = 0

      window.addEventListener("scroll", throttle(function () {
        scrollPosition = window.scrollY || document.documentElement.scrollTop

        if (self.state.isOpen === false) {
          if (scrollPosition > 20) {
            self.elements.header.classList.add('header--scrolled')
            self.elements.header.classList.remove('header--at-top')
          } else {
            self.elements.header.classList.remove('header--scrolled')
            self.elements.header.classList.add('header--at-top')
          }

          if (scrollPosition > 20) {
            if (lastScrollTop > scrollPosition) {
              self.elements.header.classList.add('header--scrolling-up')
              self.elements.header.classList.remove('header--minimised')
            } else {
              self.elements.header.classList.remove('header--scrolling-up')
              self.elements.header.classList.add('header--minimised')
            }
          } else {
            self.elements.header.classList.remove('header--scrolling-up')
            self.elements.header.classList.remove('header--minimised')
          }
          lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition
        }
      }, 50))
    }
  },

  handleScrollProgress() {
    const self = this

    window.addEventListener('scroll', throttle(function () {
      let scrollPosition = window.scrollY || document.documentElement.scrollTop
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight - document.querySelector('.footer').clientHeight
      let scrolled = (scrollPosition / height) * 100

      self.elements.headerScrollProgress.style.width = scrolled + "%"
    }, 50))
  },

  handleNavLink(headerNavLink) {
    const self = this

    const defaultText = headerNavLink.querySelector('.header__nav__link__default')
    const revealText = headerNavLink.querySelector('.header__nav__link__reveal')

		if (defaultText !== null && revealText !== null) {
			const splitDefault = new SplitText(
				defaultText,
				{ type: "chars", charsClass: "charChild" }
			)

			const splitReveal = new SplitText(
				revealText,
				{ type: "chars", charsClass: "charChild" }
			)

			gsap.set(splitReveal.chars, { y: "100%", autoAlpha: 0 });

			const tl = gsap.timeline({ paused: true });

			tl.to(splitDefault.chars, {
				y: "-60%",
				autoAlpha: 0,
				duration: .3,
				ease: "power1.inOut",
				stagger: .03
			})

			tl.to(splitReveal.chars, {
				y: "0%",
				autoAlpha: 1,
				color: "#C099F8",
				duration: .3,
				delay: -.4,
				ease: "power1.inOut",
				stagger: .03
			})

			headerNavLink.addEventListener("mouseenter", function () {
				headerNavLink.setAttribute('data-hover', true)

				if (headerNavLink.getAttribute('data-hover') == "true" && window.innerWidth >= 1024) {
					tl.play()
				}

				if (self.elements.headerApproach !== null && self.elements.headerApproach.classList.contains('header__sidemenu--open') && !headerNavLink.classList.contains('header__nav__link--approach')) {
					self.closeApproachMenu()
				}

				if (self.elements.headerProjects !== null && self.elements.headerProjects.classList.contains('header__sidemenu--open') && !headerNavLink.classList.contains('header__nav__link--projects')) {
					self.closeProjectsMenu()
				}
			})

			headerNavLink.addEventListener("mouseleave", function () {
				headerNavLink.setAttribute('data-hover', false)

				if (headerNavLink.getAttribute('data-hover') == "false" && window.innerWidth >= 1024) {
					tl.reverse()
				}
			})

			if (headerNavLink.classList.contains('header__nav__link--approach')) {
				window.headerNavLinkApproachTl = tl
			}

			if (headerNavLink.classList.contains('header__nav__link--projects')) {
				window.headerNavLinkProjectsTl = tl
			}
		}
  },

  handleNavBtn() {
    const self = this

		if (window.innerWidth >= 1024) {
			self.elements.headerNav.style.pointerEvents = 'none'
		}

    if (self.elements.headerNavBtnTrigger !== null) {
      self.elements.headerNavBtnTrigger.addEventListener('click', function (e) {
        e.preventDefault()

        if (self.elements.header.classList.contains('header--open')) {
					// Make header nav non-interactive
					//
					if (window.innerWidth >= 1024) {
						setTimeout(function() {
							self.elements.headerNav.style.pointerEvents = 'none'
						}, 500)
					}

          // Handle scroll resetting and active state
          //
          if (!self.elements.header.classList.contains('header--scrolled') && self.elements.headerNav.scrollTop > 5) {
            self.elements.headerNav.scrollTop = 0

            setTimeout(function () {
              self.elements.header.classList.remove('header--open')
            }, 300)
          } else {
            self.elements.header.classList.remove('header--open')
          }

          // Enable scroll
          //
          if (window.lenis !== undefined) {
            window.lenis.start()
          }
					document.documentElement.style.overflow = "";

          // Ensure header doesn't minimise on menu close
          //
          setTimeout(function () {
            if (window.lenis !== undefined) {
              if (window.lenis.actualScroll > 20) {
                self.elements.header.classList.add('header--scrolled')
              }
            }
          }, 500)

          // Set open state
          // delay the state change until menu transitions fully out
          //
          setTimeout(function () {
            self.state.isOpen = false
          }, 1000)

          // Temp disable of button (until transitions finish)
          //
          self.elements.headerNavBtnTrigger.style.pointerEvents = 'none'
          setTimeout(function () {
            self.elements.headerNavBtnTrigger.style.pointerEvents = ''
          }, 1000)

          // After header closed, reset scroll
          //
          setTimeout(function () {
            if (self.elements.headerNav.scrollTop > 0) {
              self.elements.headerNav.scrollTop = 0
            }
          }, 1400)

          // Toggle tab indexes
          //
          self.elements.headerLogo.tabIndex = 0
          if (self.elements.headerProjectsLink !== null) {
            self.elements.headerProjectsLink.tabIndex = 0
          }
          self.setTabIndexes(self.elements.headerNav.querySelectorAll('.cardPage, .header__nav__link'), false)

          // Handle ARIA
          //
          self.elements.headerNavBtnTrigger.setAttribute('aria-expanded', false)

          // Handle different transition state for dark header (used on light bgs)
          //
          if (self.elements.header.classList.contains('header--dark') && !self.elements.header.classList.contains('header--scrolled')) {
            setTimeout(function () {
              self.elements.header.classList.remove('header--dark-delay')
            }, 1400)
          }

					// Handle closing of sidemenus
					//
					if (self.elements.headerApproach !== null && self.elements.headerApproach.classList.contains('header__sidemenu--open')) {
						self.closeApproachMenu()
					}

					if (self.elements.headerProjects !== null && self.elements.headerProjects.classList.contains('header__sidemenu--open')) {
						self.closeProjectsMenu()
					}
        } else {
					// Make header nav interactive but with delay
					//
					if (window.innerWidth >= 1024) {
						setTimeout(function() {
							self.elements.headerNav.style.pointerEvents = ''
						}, 1000)
					}

          // Add active state
          //
          self.elements.header.classList.add('header--open')

          // Disable scroll
          //
          if (window.lenis !== undefined) {
            window.lenis.stop()
          }
					document.documentElement.style.overflow = "hidden";

          // Ensure minimise doesn't clash and cause unexitable menu
          // Also remove header scrolled, so header doesn't quickly minimise on menu close
          //
          setTimeout(function () {
            self.elements.header.classList.remove('header--minimised')
            self.elements.header.classList.remove('header--scrolled')
          }, 750)

          // Set open state
          //
          self.state.isOpen = true

          // Toggle tab indexes
          //
          self.elements.headerLogo.tabIndex = -1
          if (self.elements.headerProjectsLink !== null) {
            self.elements.headerProjectsLink.tabIndex = -1
          }
          self.elements.headerNav.querySelector('.header__nav__link').focus()
          self.setTabIndexes(self.elements.headerNav.querySelectorAll('.cardPage, .header__nav__link'), true)

          // Handle ARIA
          //
          self.elements.headerNavBtnTrigger.setAttribute('aria-expanded', true)

          // Handle different transition state for dark header (used on light bgs)
          //
          if (self.elements.header.classList.contains('header--dark') && !self.elements.header.classList.contains('header--scrolled')) {
            setTimeout(function () {
              self.elements.header.classList.add('header--dark-delay')
            }, 50)
          }
        }
      })
    }
  },

	handleApproach() {
    const self = this

		let timeout = null

    if (self.elements.headerApproachLink !== null) {
			// Desktop functionality
			//
      self.elements.headerApproachLink.addEventListener('mouseenter', function (e) {
        e.preventDefault()

				if (timeout != null) { clearTimeout(timeout); }

        timeout = setTimeout(function () {
          if (window.innerWidth >= 1024) {
						self.closeProjectsMenu()

						self.openApproachMenu()
					}
        }, 300);
      })

			self.elements.headerApproachLink.addEventListener('mouseleave', function (e) {
				if (timeout != null) {
					clearTimeout(timeout);

					timeout = null;
				}
      })

			self.elements.headerApproach.addEventListener('mouseenter', function (e) {
        e.preventDefault()

				if (window.innerWidth >= 1024) {
					self.elements.headerApproachLink.setAttribute('data-hover', true)
					window.headerNavLinkApproachTl.play()

					self.openApproachMenu()
				}
      })

			self.elements.headerApproach.addEventListener('mouseleave', function (e) {
				if (window.innerWidth >= 1024) {
					self.closeApproachMenu()

					self.elements.headerApproachLink.setAttribute('data-hover', false)
					window.headerNavLinkApproachTl.reverse()
				}
			})

			// Mobile functionality
			//
			self.elements.headerApproachLink.addEventListener('click', function (e) {
				if (window.innerWidth < 1024) {
					e.preventDefault()

					self.closeProjectsMenu()

					self.openApproachMenu()
				}
      })
    }
  },

  handleApproachClose() {
    const self = this

    if (self.elements.headerApproachCloseBtn !== null) {
      self.elements.headerApproachCloseBtn.addEventListener('click', function (e) {
        e.preventDefault()

        self.closeApproachMenu()
      })
    }

    if (self.elements.headerApproachOverlay !== null) {
      self.elements.headerApproachOverlay.addEventListener('click', function (e) {
        e.preventDefault()

        self.closeApproachMenu()
      })
    }
  },

	openApproachMenu() {
		const self = this

		if (!self.elements.header.classList.contains('header--sidemenu-open')) {
			self.elements.header.classList.add('header--sidemenu-open')
			self.elements.headerApproach.classList.add('header__sidemenu--open')

			// Handle ARIA
			//
			self.elements.headerApproachLink.setAttribute('aria-expanded', true)
			self.elements.headerApproachCloseBtn.setAttribute('aria-expanded', true)

			// Enable tab indexes
			//
			self.elements.headerApproach.querySelector('.cardPageSlim').focus()
			self.elements.headerApproachLink.tabIndex = -1
			self.elements.headerApproachCloseBtn.tabIndex = 0
			self.setTabIndexes(self.elements.headerApproach.querySelectorAll('.cardPageSlim, .header__sidemenu__view-all'), true)

			// Focus on first card
			//
			self.elements.headerApproach.querySelector('.header__sidemenu__card').focus()
		}
	},

  closeApproachMenu() {
    const self = this

		self.elements.header.classList.remove('header--sidemenu-open')
		self.elements.headerApproach.classList.remove('header__sidemenu--open')

		// Temp disable of button (until transitions finish)
		//
		self.elements.headerApproachLink.style.pointerEvents = 'none'
		setTimeout(function () {
			self.elements.headerApproachLink.style.pointerEvents = ''
		}, 250)

		// Disable tab indexes
		//
		self.elements.headerApproachLink.tabIndex = 0
		self.elements.headerApproachLink.focus()
		self.elements.headerApproachCloseBtn.tabIndex = -1
		self.setTabIndexes(self.elements.headerApproach.querySelectorAll('.cardPageSlim, .header__sidemenu__view-all'), false)

		// Handle ARIA
		//
		self.elements.headerApproachLink.setAttribute('aria-expanded', false)
		self.elements.headerApproachCloseBtn.setAttribute('aria-expanded', false)
  },

  handleProjects() {
    const self = this

		let timeout = null

    if (self.elements.headerProjectsLink !== null) {
			// Desktop functionality
			//
      self.elements.headerProjectsLink.addEventListener('mouseenter', function (e) {
        e.preventDefault()

				if (timeout != null) { clearTimeout(timeout); }

        timeout = setTimeout(function () {
          if (window.innerWidth >= 1024) {
						self.closeApproachMenu()

						self.openProjectsMenu()
					}
        }, 300);
      })

			self.elements.headerProjectsLink.addEventListener('mouseleave', function (e) {
				if (timeout != null) {
					clearTimeout(timeout);

					timeout = null;
				}
      })

			self.elements.headerProjects.addEventListener('mouseenter', function (e) {
        e.preventDefault()

				if (window.innerWidth >= 1024) {
					self.elements.headerProjectsLink.setAttribute('data-hover', true)
					window.headerNavLinkProjectsTl.play()

					self.openProjectsMenu()
				}
      })

			self.elements.headerProjects.addEventListener('mouseleave', function (e) {
				if (window.innerWidth >= 1024) {
					self.closeProjectsMenu()

					self.elements.headerProjectsLink.setAttribute('data-hover', false)
					window.headerNavLinkProjectsTl.reverse()
				}
			})

			// Mobile functionality
			//
			self.elements.headerProjectsLink.addEventListener('click', function (e) {
        e.preventDefault()

				if (window.innerWidth < 1024) {
					self.closeApproachMenu()

					self.openProjectsMenu()
				}
      })
    }
  },

  handleProjectsClose() {
    const self = this

    if (self.elements.headerProjectsCloseBtn !== null) {
      self.elements.headerProjectsCloseBtn.addEventListener('click', function (e) {
        e.preventDefault()

        self.closeProjectsMenu()
      })
    }

    if (self.elements.headerProjectsOverlay !== null) {
      self.elements.headerProjectsOverlay.addEventListener('click', function (e) {
        e.preventDefault()

        self.closeProjectsMenu()
      })
    }
  },

	openProjectsMenu() {
		const self = this

		if (!self.elements.header.classList.contains('header--sidemenu-open')) {
			self.elements.header.classList.add('header--sidemenu-open')
			self.elements.headerProjects.classList.add('header__sidemenu--open')

			// Handle ARIA
			//
			self.elements.headerProjectsLink.setAttribute('aria-expanded', true)
			self.elements.headerProjectsCloseBtn.setAttribute('aria-expanded', true)

			// Enable tab indexes
			//
			self.elements.headerProjects.querySelector('.cardProjectSlim').focus()
			self.elements.headerProjectsLink.tabIndex = -1
			self.elements.headerProjectsCloseBtn.tabIndex = 0
			self.setTabIndexes(self.elements.headerProjects.querySelectorAll('.cardProjectSlim, .header__sidemenu__view-all'), true)

			// Focus on first card
			//
			self.elements.headerProjects.querySelector('.header__sidemenu__card').focus()
		}
	},

  closeProjectsMenu() {
    const self = this

		self.elements.header.classList.remove('header--sidemenu-open')
		self.elements.headerProjects.classList.remove('header__sidemenu--open')

		// Temp disable of button (until transitions finish)
		//
		self.elements.headerProjectsLink.style.pointerEvents = 'none'
		setTimeout(function () {
			self.elements.headerProjectsLink.style.pointerEvents = ''
		}, 250)

		// Disable tab indexes
		//
		self.elements.headerProjectsLink.tabIndex = 0
		self.elements.headerProjectsLink.focus()
		self.elements.headerProjectsCloseBtn.tabIndex = -1
		self.setTabIndexes(self.elements.headerProjects.querySelectorAll('.cardProjectSlim, .header__sidemenu__view-all'), false)

		// Handle ARIA
		//
		self.elements.headerProjectsLink.setAttribute('aria-expanded', false)
		self.elements.headerProjectsCloseBtn.setAttribute('aria-expanded', false)
  },

  setTabIndexes(elements, isFocusable) {
    const self = this

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]

      if (isFocusable === true) {
        element.tabIndex = '0'
      } else {
        element.tabIndex = '-1'
      }
    }
  },

  setNavHeight() {
    const self = this

    if (self.elements.headerNav !== null) {
      self.elements.headerNav.style.height = (window.innerHeight) + "px"
    }

		if (window.innerWidth < 1024) {
			if (self.elements.headerProjects !== null) {
				self.elements.headerProjects.style.top = (self.elements.header.clientHeight) + "px"
				self.elements.headerProjects.style.height = (window.innerHeight - self.elements.header.clientHeight) + "px"
			}

			if (self.elements.headerApproach !== null) {
				self.elements.headerApproach.style.top = (self.elements.header.clientHeight) + "px"
				self.elements.headerApproach.style.height = (window.innerHeight - self.elements.header.clientHeight) + "px"
			}
		}
  },
}

window.addEventListener('load', function () {
  header.init()
})
